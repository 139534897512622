@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;900&display=swap');

body {
    box-sizing: border-box;
    margin: 0;
    background-color: black;
}

#maintenance {
    box-sizing: border-box;

    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    color: white;
    text-align: center;

    h1 {
        font-size: 2em;
        font-family: "Montserrat", sans-serif;
        text-transform: uppercase;
        max-width: 700px;
        padding: 2rem;

    }

    h4 {
        position: absolute;
        top: 0;

        font-size: 2em;
        text-transform: lowercase;
        font-family: "Montserrat", sans-serif;
    }

    a#get_notif {
        display: flex;
        justify-content: center;
        align-items: center;

        border: 3px solid white;
        border: 3px solid white;
        background: transparent;

        text-decoration: none;
        padding: 21px;
        width: 133px;
        font-family: "Montserrat", sans-serif;
        color: white;
        border-radius: 5px;
        transition: all 0.2s;

        &:hover {
            border: 3px solid black;
            background-color: white;
            color: black;
        }

    }

    .social_media_icons {
        box-sizing: border-box;
        width: 100%;
        max-width: 400px;
        // position: fixed;
        bottom: 0;
        padding: 55px 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        a {
            color: white;
            font-size: 34px;
            margin: 13px;
        }
    }
}